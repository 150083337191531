/*
 * PassengersInfo Messages
 *
 * This contains all the text for the PassengersInfo container.
 */

import { defineMessages } from 'react-intl';
import {
  EBookingClass,
  EIdentityDocumentType,
  EPassengerTitle,
  ETripType,
} from '../../common/types';
import {
  FORM_VALIDATION_ERROR,
  FORM_VALIDATION_INVALID_DATE,
  FORM_VALIDATION_INVALID_EMAIL,
  FORM_VALIDATION_INVALID_EXPIRATION_DATE,
  FORM_VALIDATION_REQUIRED_FIELD,
} from './View/Form/schema';

export const passengersInfoScope = 'app.containers.PassengersInfo2';

export const passengersInfoMessages = {
  bestDeal: {
    id: `${passengersInfoScope}.bestDeal`,
    defaultMessage: 'La meilleure offre',
    en: 'The best deal',
    it: 'L’offerta migliore',
    es: 'La mejor oferta',
    de: 'Das beste Angebot',
    nl: 'De beste deal',
  },
  upgradeToFirstClass: {
    id: `${passengersInfoScope}.upgradeToFirstClass`,
    defaultMessage: 'Passer à la vitesse supérieure',
    en: 'Upgrade to first class',
    it: 'Passare in prima classe',
    es: 'Pasar a primera clase',
    de: 'Auf erste Klasse upgraden',
    nl: 'Upgrade naar eerste klas',
  },
  [ETripType.OUTBOUND]: {
    id: `${passengersInfoScope}.${ETripType.OUTBOUND}`,
    defaultMessage: 'Aller',
    en: 'Outbound',
    it: 'Andata',
    es: 'Salida',
    de: 'Gehen',
    nl: 'Uitgaand',
  },
  [ETripType.RETURN]: {
    id: `${passengersInfoScope}.${[ETripType.RETURN]}`,
    defaultMessage: 'Retour',
    en: 'Return',
    it: 'Ritorno',
    es: 'Vuelta',
    de: 'Zurück',
    nl: 'Terug',
  },
  passengersFormSectionTitle: {
    id: `${passengersInfoScope}.passengersFormSectionTitle`,
    defaultMessage: 'Qui part en voyage ?',
    en: 'Who is traveling ?',
    it: 'Chi parte in viaggio ?',
    es: '¿ Quién va de viaje ?',
    de: 'Wer reist ?',
    nl: 'Wie gaat er op reis ?',
  },
  [EPassengerTitle.MR]: {
    id: `${passengersInfoScope}.${EPassengerTitle.MR}`,
    defaultMessage: 'Monsieur',
    en: 'Mr.',
    it: 'Sig.',
    es: 'Sr.',
    de: 'Herr',
    nl: 'Dhr.',
  },
  [EPassengerTitle.MS]: {
    id: `${passengersInfoScope}.${EPassengerTitle.MS}`,
    defaultMessage: 'Madame',
    en: 'Mrs',
    it: 'Signora',
    es: 'Sra.',
    de: 'Frau',
    nl: 'Mevr.',
  },
  [`${EPassengerTitle.MR}.reduce`]: {
    id: `${passengersInfoScope}.${EPassengerTitle.MR}`,
    defaultMessage: 'M.',
    en: 'Mr.',
    it: 'Sig.',
    es: 'Sr.',
    de: 'Herr',
    nl: 'Dhr.',
  },
  [`${EPassengerTitle.MS}.reduce`]: {
    id: `${passengersInfoScope}.${EPassengerTitle.MS}`,
    defaultMessage: 'Mme',
    en: 'Mrs',
    it: 'Signora',
    es: 'Sra.',
    de: 'Frau',
    nl: 'Mevr.',
  },
  addDiscountCard: {
    id: `${passengersInfoScope}.addDiscountCard`,
    defaultMessage: 'Ajouter une carte de réduction',
    en: 'Add Discount Card',
    it: 'Aggiungi una carta sconto',
    es: 'Agregar una tarjeta de descuento',
    de: 'Rabattkarte hinzufügen',
    nl: 'Kortingkaart toevoegen',
  },
  discountCardWarning: {
    id: `${passengersInfoScope}.discountCardWarning`,
    defaultMessage:
      'Attention, vos noms, prénoms et dates de naissance doivent correspondre à votre numéro de carte.',
    en: 'Attention, your names, first names, and dates of birth must match your card number.',
    it: 'Attenzione, i vostri nomi, cognomi e date di nascita devono corrispondere al numero della carta.',
    es: 'Atención, sus nombres, apellidos y fechas de nacimiento deben coincidir con el número de su tarjeta.',
    de: 'Achtung, Ihre Namen, Vornamen und Geburtsdaten müssen mit Ihrer Kartennummer übereinstimmen.',
    nl: 'Let op, uw namen, voornamen en geboortedata moeten overeenkomen met uw kaartnummer.',
  },
  addCardSuccess: {
    id: `${passengersInfoScope}.addCardSuccess`,
    defaultMessage: 'C’est tout bon, votre carte a bien été ajoutée.✌️',
    en: 'Your card has been successfully added.✌️',
    it: 'Tutto a posto, la tua carta è stata aggiunta con successo.✌️',
    es: '¡Listo! Tu tarjeta ha sido agregada correctamente.✌️',
    de: 'Alles klar, Ihre Karte wurde erfolgreich hinzugefügt.✌️',
    nl: 'Het is gelukt, uw kaart is succesvol toegevoegd.✌️',
  },
  removeCardSuccess: {
    id: `${passengersInfoScope}.removeCardSuccess`,
    defaultMessage: 'Votre carte de réduction a bien été retirée.',
    en: 'Your discount card has been successfully removed.',
    it: 'La tua carta sconto è stata rimossa con successo.',
    es: '¡Tu tarjeta de descuento ha sido retirada correctamente!',
    de: 'Ihre Rabattkarte wurde erfolgreich entfernt.',
    nl: 'Uw kortingskaart is succesvol verwijderd.',
  },
  firstNameInputLabel: {
    id: `${passengersInfoScope}.firstNameInputLabel`,
    defaultMessage: 'Prénom',
    en: 'First Name',
    it: 'Nome',
    es: 'Nombre',
    de: 'Vorname',
    nl: 'Voornaam',
  },
  lastNameInputLabel: {
    id: `${passengersInfoScope}.lastNameInputLabel`,
    defaultMessage: 'Nom',
    en: 'Last Name',
    it: 'Cognome',
    es: 'Apellido',
    de: 'Name',
    nl: 'Naam',
  },
  dateOfBirthInputLabel: {
    id: `${passengersInfoScope}.dateOfBirthInputLabel`,
    defaultMessage: 'Date de naissance (jj/mm/aaaa)',
    en: 'Date of Birth (dd/mm/yyyy)',
    it: 'Data di nascita (gg/mm/aaaa)',
    es: 'Fecha de nacimiento (dd/mm/aaaa)',
    de: 'Geburtsdatum (tt/mm/jjjj)',
    nl: 'Geboortedatum (dd/mm/jjjj)',
  },
  idDocumentHeader: {
    id: `${passengersInfoScope}.idDocumentHeader`,
    defaultMessage: "Document d'identité",
    en: 'ID document',
    it: "Documento d'identità",
    es: 'Documento de identidad',
    de: 'Ausweispapiere',
    nl: 'Identiteitsbewijs',
  },
  idDocumentCountry: {
    id: `${passengersInfoScope}.idDocumentCountry`,
    defaultMessage: "Pays d'émmission",
    en: 'Issuing country',
    it: 'Paese di emissione',
    es: 'País de emisión',
    de: 'Ausstellungsland',
    nl: 'Uitgevend land',
  },
  idDocumentType: {
    id: `${passengersInfoScope}.idDocumentType`,
    defaultMessage: 'Type de document',
    en: 'Document type',
    it: 'Tipo di documento',
    es: 'Tipo de documento',
    de: 'Dokumententyp',
    nl: 'Documenttype',
  },
  idDocumentNumber: {
    id: `${passengersInfoScope}.idDocumentNumber`,
    defaultMessage: 'Numéro de document',
    en: 'Number of document',
    it: 'Numero di documento',
    es: 'Número de documento',
    de: 'Nummer des Dokuments',
    nl: 'Nummer van document',
  },
  idDocumentExpirationDate: {
    id: `${passengersInfoScope}.idDocumentExpirationDate`,
    defaultMessage: 'Date d’expiration (jj/mm/aaaa)',
    en: 'Expiration date (dd/mm/yyyy)',
    it: 'Data di scadenza (gg/mm/aaaa)',
    es: 'Fecha de caducidad (dd/mm/aaaa)',
    de: 'Ablaufdatum (tt/mm/jjjj)',
    nl: 'Vervaldatum (dd/mm/jjjj)',
  },
  [FORM_VALIDATION_INVALID_EXPIRATION_DATE]: {
    id: `${passengersInfoScope}.${FORM_VALIDATION_INVALID_EXPIRATION_DATE}`,
    defaultMessage:
      'La date d’expiration doit être dans le futur et respecter le format jj/mm/aaaa',
    en: 'The expiration date must be in the future and follow the format dd/mm/yyyy',
    it: 'La data di scadenza deve essere nel futuro e rispettare il formato gg/mm/aaaa',
    es: 'La fecha de vencimiento debe estar en el futuro y seguir el formato dd/mm/aaaa',
    de: 'Das Ablaufdatum muss in der Zukunft liegen und das Format tt/mm/jjjj einhalten',
    nl: 'De vervaldatum moet in de toekomst liggen en het formaat dd/mm/jjjj volgen',
  },
  [EIdentityDocumentType.PASSPORT]: {
    id: `${passengersInfoScope}.${EIdentityDocumentType.PASSPORT}`,
    defaultMessage: 'Passeport',
    en: 'Passport',
    it: 'Passaporto',
    es: 'Pasaporte',
    de: 'Reisepass',
    nl: 'Paspoort',
  },
  [EIdentityDocumentType.IDENTITY_CARD]: {
    id: `${passengersInfoScope}.${EIdentityDocumentType.IDENTITY_CARD}`,
    defaultMessage: 'Carte d’identité',
    en: 'ID card',
    it: 'Carta d’identità',
    es: 'Tarjeta de identidad',
    de: 'Personalausweis',
    nl: 'Identiteitskaart',
  },
  passenger: {
    id: `${passengersInfoScope}.passenger`,
    defaultMessage: 'Passager',
    en: 'Passenger',
    it: 'Passeggero',
    es: 'Pasajero',
    de: 'Passagier',
    nl: 'Passagier',
  },
  updatePassengers: {
    id: `${passengersInfoScope}.updatePassengers`,
    defaultMessage: 'Ajouter/supprimer des passagers',
    en: 'Add/Remove Passengers',
    it: 'Aggiungi/Rimuovi passeggeri',
    es: 'Agregar/Quitar pasajeros',
    de: 'Passagiere hinzufügen/entfernen',
    nl: 'Passagiers toevoegen/verwijderen',
  },
  validate: {
    id: `${passengersInfoScope}.validate`,
    defaultMessage: 'Valider',
    en: 'Validate',
    it: 'Conferma',
    es: 'Validar',
    de: 'Bestätigen',
    nl: 'Valideren',
  },
  addPassenger: {
    id: `${passengersInfoScope}.addPassenger`,
    defaultMessage: 'Ajouter un autre passager',
    en: 'Add another passenger',
    it: 'Aggiungi un altro passeggero',
    es: 'Agregar otro pasajero',
    de: 'Weiteren Passagier hinzufügen',
    nl: 'Nog een passagier toevoegen',
  },
  addPassengerSuccess: {
    id: `${passengersInfoScope}.addPassengerSuccess`,
    defaultMessage: 'Les passagers supplémentaires ont bien été ajoutés',
    en: 'Additional passengers have been successfully added',
    it: 'I passeggeri aggiuntivi sono stati aggiunti correttamente',
    es: 'Los pasajeros adicionales han sido agregados exitosamente',
    de: 'Zusätzliche Passagiere wurden erfolgreich hinzugefügt',
    nl: 'Extra passagiers zijn succesvol toegevoegd',
  },
  discountCardNumber: {
    id: `${passengersInfoScope}.discountCardNumber`,
    defaultMessage: 'Numéro de carte de réduction',
    en: 'Discount Card Number',
    it: 'Numero di carta sconto',
    es: 'Número de tarjeta de descuento',
    de: 'Rabattkartennummer',
    nl: 'Kortingskaartnummer',
  },
  customerFormSectionTitle: {
    id: `${passengersInfoScope}.customerFormSectionTitle`,
    defaultMessage: 'Qui réserve le voyage ?',
    en: 'Who books the trip?',
    it: 'Chi prenota il viaggio?',
    es: '¿Quién reserva el viaje?',
    de: 'Wer bucht die Reise?',
    nl: 'Wie boekt de reis?',
  },
  customerFormSectionSubject: {
    id: `${passengersInfoScope}.customerFormSectionSubject`,
    defaultMessage:
      'Nous avons besoin de votre email pour vous tenir informé et vous envoyer les billets.',
    en: 'We need your email to keep you informed and send you the tickets.',
    it: 'Abbiamo bisogno della tua email per tenerti informato/a e inviarti i biglietti.',
    es: 'Necesitamos tu correo electrónico para mantenerte informado y enviarte las entradas.',
    de: 'Wir benötigen Ihre E-Mail-Adresse, um Sie auf dem Laufenden zu halten und Ihnen die Tickets zu senden.',
    nl: 'We hebben uw e-mailadres nodig om u op de hoogte te houden en de tickets naar u te sturen.',
  },
  customerEmailInputLabel: {
    id: `${passengersInfoScope}.customerEmailInputLabel`,
    defaultMessage: 'Adresse email',
    en: 'Email address',
    it: 'Indirizzo email',
    es: 'Dirección de correo electrónico',
    de: 'E-Mail-Adresse',
    nl: 'E-mailadres',
  },
  customerPhoneNumberInputLabel: {
    id: `${passengersInfoScope}.customerPhoneNumberInputLabel`,
    defaultMessage: 'N° de téléphone (optionnel)',
    en: 'Phone number (optional)',
    it: 'Numero di telefono (opzionale)',
    es: 'Número de teléfono (opcional)',
    de: 'Telefonnummer (optional)',
    nl: 'Telefoonnummer (optioneel)',
  },
  selectPassenger: {
    id: `${passengersInfoScope}.selectPassenger`,
    defaultMessage: 'Sélectionnez un passager',
    en: 'Select a passenger',
    it: 'Seleziona un passeggero',
    es: 'Selecciona un pasajero',
    de: 'Wählen Sie einen Passagier aus',
    nl: 'Selecteer een passagier',
  },
  otherCustomer: {
    id: `${passengersInfoScope}.otherCustomer`,
    defaultMessage: 'Autre',
    en: 'Other',
    it: 'Altro',
    es: 'Otro',
    de: 'Andere',
    nl: 'Anders',
  },
  traveller: {
    id: `${passengersInfoScope}.traveller`,
    defaultMessage: 'Voyageur',
    en: 'Traveller',
    it: 'Viaggiatore',
    es: 'Viajero',
    de: 'Reisender',
    nl: 'Reiziger',
  },
  acceptTerms: {
    id: `${passengersInfoScope}.acceptTerms`,
    defaultMessage:
      "Accepter les {termsLink} de Tictactrip, l'utilisation des données en accord avec la {privacyLink}{additionalCondition}.",
    en: 'Accept the {termsLink} of Tictactrip, the use of data in accordance with the {privacyLink}{additionalCondition}.',
    it: "Accetta i {termsLink} di Tictactrip, l'utilizzo dei dati in conformità con la {privacyLink}{additionalCondition}.",
    es: 'Aceptar los {termsLink} de Tictactrip, el uso de datos de acuerdo con la {privacyLink}{additionalCondition}.',
    de: 'Akzeptieren Sie die {termsLink} von Tictactrip, die Nutzung von Daten gemäß der {privacyLink}{additionalCondition}.',
    nl: 'Accepteer de {termsLink} van Tictactrip, het gebruik van gegevens in overeenstemming met het {privacyLink}{additionalCondition}.',
  },
  sncfConditions: {
    id: `${passengersInfoScope}.sncfConditions`,
    defaultMessage: ' et les {termsLink} de la SNCF',
    en: ', and the {termsLink} of SNCF',
    it: ' e i {termsLink} privacy di SNCF',
    es: ' y los {termsLink} de SNCF',
    de: ' und die {termsLink} von SNCF',
    nl: ' en de {termsLink} van SNCF',
  },
  ouigoConditions: {
    id: `${passengersInfoScope}.ouigoConditions`,
    defaultMessage: ' et les {termsLink} de Ouigo',
    en: ', and the {termsLink} of Ouigo',
    it: ' e i {termsLink} di Ouigo',
    es: ' y los {termsLink} de Ouigo',
    de: ' und die {termsLink} von Ouigo',
    nl: ' en de {termsLink} van Ouigo',
  },
  termsLink: {
    id: `${passengersInfoScope}.termsLink`,
    defaultMessage: 'conditions générales de vente',
    en: 'terms and conditions of sale',
    it: 'condizioni generali di vendita',
    es: 'condiciones generales de venta',
    de: 'Allgemeine Geschäftsbedingungen',
    nl: 'algemene verkoopvoorwaarden',
  },
  privacyLink: {
    id: `${passengersInfoScope}.privacyLink`,
    defaultMessage: 'politique de confidentialité',
    en: 'privacy policy',
    it: 'politica sulla privacy',
    es: 'política de privacidad',
    de: 'Datenschutzbestimmungen',
    nl: 'privacybeleid',
  },
  receiveOffers: {
    id: `${passengersInfoScope}.receiveOffers`,
    defaultMessage:
      'Recevoir les bons plans voyages et les cookies pour économiser un max',
    en: 'Receive travel offers and cookies to save big',
    it: 'Ricevi offerte di viaggio e cookie per risparmiare un sacco',
    es: 'Recibe ofertas de viaje y cookies para ahorrar al máximo',
    de: 'Erhalten Sie Reiseangebote und Cookies, um viel zu sparen',
    nl: 'Ontvang reisaanbiedingen en cookies om veel geld te besparen',
  },
  mailSuggestion: {
    id: `${passengersInfoScope}.mailSuggestion`,
    defaultMessage: 'Voulez-vous dire {email} ?',
    en: 'Did you mean {email} ?',
    it: 'Intendevi dire {email} ?',
    es: '¿ Quisiste decir {email} ?',
    de: 'Meinten Sie {email} ?',
    nl: 'Bedoelde u {email} ?',
  },
  confirmMail: {
    id: `${passengersInfoScope}.confirmMail`,
    defaultMessage: 'Confirmer que {email} est une adresse email valide.',
    en: 'Confirm that {email} is a valid email address.',
    it: 'Conferma che {email} è un indirizzo email valido.',
    es: 'Confirmar que {email} es una dirección de correo electrónico válida.',
    de: 'Bestätigen Sie, dass {email} eine gültige E-Mail-Adresse ist.',
    nl: 'Bevestig dat {email} een geldig e-mailadres is.',
  },
  availableSeatsForATrip: {
    id: `${passengersInfoScope}.availableSeatsForATrip`,
    defaultMessage: '{availableSeatsNumber} places restantes pour ce trajet.',
    en: '{availableSeatsNumber} remaining places for this trip.',
    it: '{availableSeatsNumber} posti rimanenti per questo viaggio.',
    es: '{availableSeatsNumber} plazas que quedan para este viaje.',
    de: '{availableSeatsNumber} verbleibende Plätze für diese Fahrt.',
    nl: '{availableSeatsNumber} plaatsen over voor deze reis.',
  },
  tripDetails: {
    id: `${passengersInfoScope}.tripDetails`,
    defaultMessage: 'Détails du trajet',
    en: 'Trip Details',
    it: 'Dettagli del viaggio',
    es: 'Detalles del viaje',
    de: 'Reisedetails',
    nl: 'Reisdetails',
  },
  tripClasses: {
    id: `${passengersInfoScope}.tripClasses`,
    defaultMessage: 'Classes :',
    en: 'Classes :',
    it: 'Classi :',
    es: 'Clases :',
    de: 'Klassen :',
    nl: 'Klassen :',
  },
  tripFareNames: {
    id: `${passengersInfoScope}.tripFareNames`,
    defaultMessage: 'Tarif : ',
    en: 'Fare : ',
    it: 'Tariffa : ',
    es: 'Tarifa : ',
    de: 'Tarief : ',
    nl: 'tarief : ',
  },
  viewClassesAndConditions: {
    id: `${passengersInfoScope}.viewClassesAndConditions`,
    defaultMessage: 'Voir les classes et conditions',
    en: 'View Classes and Conditions',
    it: 'Visualizza classi e condizioni',
    es: 'Ver clases y condiciones',
    de: 'Klassen und Bedingungen anzeigen',
    nl: 'Bekijk klassen en voorwaarden',
  },
  availableSeats: {
    id: `${passengersInfoScope}.availableSeats`,
    defaultMessage: 'Places restantes: {availableSeatsNumber}',
    en: 'Remaining places: {availableSeatsNumber}',
    it: 'Posti rimanenti: {availableSeatsNumber}',
    es: 'Plazas restantes: {availableSeatsNumber}',
    de: 'Verbleibende Plätze: {availableSeatsNumber}',
    nl: 'Resterende plaatsen: {availableSeatsNumber}',
  },
  updateClassesSuccess: {
    id: `${passengersInfoScope}.updateClassesSuccess`,
    defaultMessage: 'Changement de classe validé.',
    en: 'Class change validated.',
    it: 'Cambio di classe convalidato.',
    es: 'Cambio de clase validado.',
    de: 'Klassenwechsel bestätigt.',
    nl: 'Klassewijziging gevalideerd.',
  },
  proceedToPayment: {
    id: `${passengersInfoScope}.proceedToPayment`,
    defaultMessage: 'Passer au paiement',
    en: 'Proceed to Payment',
    it: 'Procedi al pagamento',
    es: 'Continuar con el pago',
    de: 'Zur Zahlung gehen',
    nl: 'Ga naar betaling',
  },
  numberOfPassengers: {
    id: `${passengersInfoScope}.numberOfPassengers`,
    defaultMessage:
      '{count, plural, =0 {0 passager} one {1 passager} other {{count} passagers}}',
    en: '{count, plural, =0 {0 passengers} one {1 passenger} other {{count} passengers}}',
    it: '{count, plural, =0 {0 passeggero} one {1 passeggero} other {{count} passeggeri}}',
    es: '{count, plural, =0 {0 pasajero} one {1 pasajero} other {{count} pasajeros}}',
    de: '{count, plural, =0 {0 Passagier} one {1 Passagier} other {{count} Passagiere}}',
    nl: '{count, plural, =0 {0 passagier} one {1 passagier} other {{count} passagiers}}',
  },
  noClassAndNoConditions: {
    id: `${passengersInfoScope}.noClassAndNoConditions`,
    defaultMessage:
      'Sur ce trajet, notre partenaire ne propose pas de changement de classe ou de conditions particulières. C’est pas cool mais on ne peut (vraiment) rien faire.',
    en: "On this trip, our partner does not offer a change of class or special conditions. It's not cool, but we can't (really) do anything about it.",
    it: 'In questo viaggio, il nostro partner non offre cambi di classe o condizioni speciali. Non è bello, ma non possiamo (veramente) fare nulla al riguardo.',
    es: 'En este viaje, nuestro socio no ofrece cambios de clase ni condiciones especiales. No es genial, pero realmente no podemos hacer nada al respecto.',
    de: 'Auf dieser Fahrt bietet unser Partner keine Klassenänderung oder besondere Bedingungen an. Es ist nicht cool, aber wir können (wirklich) nichts dagegen tun.',
    nl: 'Op deze reis biedt onze partner geen klassewijziging of speciale voorwaarden aan. Het is niet leuk, maar we kunnen er echt niets aan doen.',
  },
  trip: {
    id: `${passengersInfoScope}.trip`,
    defaultMessage: 'Trajet',
    en: 'Journey',
    it: 'Viaggio',
    es: 'Viaje',
    de: 'Reise',
    nl: 'Reis',
  },
  changeStation: {
    id: `${passengersInfoScope}.changeStation`,
    defaultMessage: 'pour changer de gare',
    en: 'change station',
    it: 'cambiare stazione',
    es: 'cambiar de estación',
    de: 'bahnhof wechseln',
    nl: 'station veranderen',
  },
  tripParts: {
    id: `${passengersInfoScope}.tripParts`,
    defaultMessage:
      '{index, selectordinal, one {1er} two {2ème} few {{index}ème} other {{index}ème}} partie',
    en: '{index, selectordinal, one {1st} two {2nd} few {{index}th} other {{index}th}} part',
    it: '{index, selectordinal, one {1a} two {2a} few {{index}a} other {{index}a}} parte',
    es: '{index, selectordinal, one {1a} two {2a} few {{index}a} other {{index}a}} parte',
    de: '{index, selectordinal, one {1.} two {2.} few {{index}.} other {{index}.}} teil',
    nl: '{index, selectordinal, one {1e} two {2e} few {{index}e} other {{index}e}} deel',
  },
  classesAndConditions: {
    id: `${passengersInfoScope}.classesAndConditions`,
    defaultMessage: 'Classes et conditions',
    en: 'Classes and conditions',
    it: 'Classi e condizioni',
    es: 'Clases y condiciones',
    de: 'Klassen und Bedingungen',
    nl: 'Klassen en voorwaarden',
  },
  patchCartLoading: {
    id: `${passengersInfoScope}.patchCartLoading`,
    defaultMessage: 'Préparation de votre trajet...',
    en: 'Preparing your journey...',
    it: 'Preparazione del tuo viaggio...',
    es: 'Preparando tu viaje...',
    de: 'Vorbereitung Ihrer Reise...',
    nl: 'Uw reis voorbereiden...',
  },
  totalPrice: {
    id: `${passengersInfoScope}.totalPrice`,
    defaultMessage: 'Total commande',
    en: 'Total order',
    it: 'Totale ordine',
    es: 'Total pedido',
    de: 'Gesamtbestellung',
    nl: 'Totaal bestelling',
  },
  and: {
    id: `${passengersInfoScope}.and`,
    defaultMessage: 'et',
    en: 'and',
    it: 'e',
    es: 'y',
    de: 'und',
    nl: 'en',
  },
  firstClass: {
    id: `${passengersInfoScope}.firstClass`,
    defaultMessage: '1ere',
    en: '1st',
    it: '1a',
    es: '1ra',
    de: '1.',
    nl: '1e',
  },
  secondClass: {
    id: `${passengersInfoScope}.secondClass`,
    defaultMessage: '2nde',
    en: '2nd',
    it: '2a',
    es: '2da',
    de: '2.',
    nl: '2e',
  },

  goodDeal: {
    id: `${passengersInfoScope}.goodDeal`,
    defaultMessage: 'Très (très) bon plan',
    en: 'Very (very) good deal',
    it: 'Affare molto (molto) buono',
    es: 'Muy (muy) buen plan',
    de: 'Sehr (sehr) gutes Angebot',
    nl: 'Zeer (zeer) goede deal',
  },
  bestDealMessage: {
    id: `${passengersInfoScope}.bestDealMessage`,
    defaultMessage: `La 1ère classe pour {price} {isNegative, select,
      true {de moins}
      other {de plus}
    } ! ✌️`,
    en: `First class for {price} {isNegative, select,
      true {off}
      other {extra}
    }! ✌️`,
    it: `Prima classe a {price} {isNegative, select,
      true {di sconto}
      other {in più}
    }! ✌️`,
    es: `¡Primera clase con {price} {isNegative, select,
      true {de descuento}
      other {extra}
    }! ✌️`,
    de: `Erste Klasse für {price} {isNegative, select,
      true {Rabatt}
      other {Aufpreis}
    }! ✌️`,
    nl: `Eerste klas met {price} {isNegative, select,
      true {korting}
      other {extra}
    }! ✌️`,
  },
  firstClassDealMessage: {
    id: `${passengersInfoScope}.firstClassDealMessage`,
    defaultMessage:
      'La 1ère classe est à {price} {isNegative, select, true {de moins} other {de plus}} !',
    en: 'First class is {price} {isNegative, select, true {less} other {more}}!',
    it: 'La prima classe è a {price} {isNegative, select, true {di meno} other {in più}}!',
    es: '¡La primera clase es {price} {isNegative, select, true {menos} other {más}}!',
    de: 'Die erste Klasse kostet {price} {isNegative, select, true {weniger} other {mehr}}!',
    nl: 'Eerste klas is {price} {isNegative, select, true {minder} other {meer}}!',
  },
  [EBookingClass.FIRST_CLASS]: {
    id: `${passengersInfoScope}.${EBookingClass.FIRST_CLASS}`,
    defaultMessage: '1ère classe',
    en: 'First class',
    it: 'Prima classe',
    es: 'Primera clase',
    de: 'Erste Klasse',
    nl: 'Eerste klasse',
  },
  [EBookingClass.SECOND_CLASS]: {
    id: `${passengersInfoScope}.${EBookingClass.SECOND_CLASS}`,
    defaultMessage: '2nde classe',
    en: 'Second class',
    it: 'Seconda classe',
    es: 'Segunda clase',
    de: 'Zweite Klasse',
    nl: 'Tweede klasse',
  },
  [EBookingClass.BERTHS_IN_1ST_CLASS]: {
    id: `${passengersInfoScope}.${EBookingClass.BERTHS_IN_1ST_CLASS}`,
    defaultMessage: '1ère classe - couchette',
    en: 'First Class - berth',
    it: 'Prima Classe - cuccetta',
    es: 'Primera Clase - litera',
    de: 'Erste Klasse - liegeplatz',
    nl: 'Eerste Klasse - slaapplaats',
  },
  [EBookingClass.BERTHS_IN_2ND_CLASS]: {
    id: `${passengersInfoScope}.${EBookingClass.BERTHS_IN_2ND_CLASS}`,
    defaultMessage: '2nde classe - couchette',
    en: 'Second Class - berth',
    it: 'Seconda classe - cuccetta',
    es: 'Segunda clase - litera',
    de: 'Zweite Klasse - liegeplatz',
    nl: 'Tweede klasse - slaapplaats',
  },
  [EBookingClass.RECLINING_SEATS]: {
    id: `${passengersInfoScope}.${EBookingClass.RECLINING_SEATS}`,
    defaultMessage: 'Siège inclinable',
    en: 'Reclining seat',
    it: 'Sedile reclinabile',
    es: 'Asiento reclinable',
    de: 'Neigbarer Sitz',
    nl: 'Verstelbare stoel',
  },
  paymentValidated: {
    id: `${passengersInfoScope}.paymentValidated`,
    defaultMessage: 'Paiement validé',
    en: 'Payment validated',
    it: 'Pagamento convalidato',
    es: 'Pago validado',
    de: 'Zahlung bestätigt',
    nl: 'Betaling gevalideerd',
  },
  waitConfirmation: {
    id: `${passengersInfoScope}.waitConfirmation`,
    defaultMessage:
      'Gardez cette fenêtre ouverte ! La confirmation de votre commande arrive.',
    en: 'Keep this window open! The confirmation of your order is coming.',
    it: 'Mantieni questa finestra aperta! La conferma del tuo ordine sta arrivando.',
    es: '¡Mantén esta ventana abierta! La confirmación de tu pedido está llegando.',
    de: 'Halten Sie dieses Fenster geöffnet! Die Bestätigung Ihrer Bestellung kommt.',
    nl: 'Houd dit venster open! De bevestiging van uw bestelling komt eraan.',
  },
  loadingCart: {
    id: `${passengersInfoScope}.loadingCart`,
    defaultMessage: 'Préparation de votre commande...',
    en: 'Preparing your order...',
    it: 'Preparazione del vostro ordine...',
    es: 'Preparación de su pedido...',
    de: 'Vorbereitung Ihrer Bestellung...',
    nl: 'Voorbereiding van uw bestelling...',
  },
  paymentFailed: {
    id: `${passengersInfoScope}.paymentFailed`,
    defaultMessage:
      'Votre paiement a été refusé. Vérifiez vos informations et réessayez.',
    en: 'Your payment has been declined. Please check your information and try again.',
    it: 'Il pagamento è stato rifiutato. Controlla le tue informazioni e riprova.',
    es: 'Se ha rechazado su pago. Por favor, verifique su información y vuelva a intentarlo.',
    de: 'Ihre Zahlung wurde abgelehnt. Bitte überprüfen Sie Ihre Informationen und versuchen Sie es erneut.',
    nl: 'Uw betaling is geweigerd. Controleer uw informatie en probeer het opnieuw.',
  },
  paymentFailedBankInternalError: {
    id: `${passengersInfoScope}.paymentFailedBankInternalError`,
    defaultMessage:
      'Une erreur interne de la banque a empêché le paiement. Veuillez réessayer.',
    en: 'An internal bank error has prevented the payment. Please try again.',
    it: 'Un errore interno della banca ha impedito il pagamento. Per favore, riprova.',
    es: 'Un error interno del banco ha impedido el pago. Por favor, inténtalo de nuevo.',
    de: 'Ein interner Bankfehler hat die Zahlung verhindert. Bitte versuchen Sie es erneut.',
    nl: 'Een interne bankfout heeft de betaling verhinderd. Probeer het alstublieft opnieuw.',
  },
  paymentFailedBankRefusedPayment: {
    id: `${passengersInfoScope}.paymentFailedBankRefusedPayment`,
    defaultMessage:
      'La banque a refusé le paiement, veuillez vérifier vos informations et réessayer.',
    en: 'The bank has refused the payment, please check your details and try again.',
    it: 'La banca ha rifiutato il pagamento, si prega di controllare i propri dati e riprovare.',
    es: 'El banco ha rechazado el pago, por favor verifique sus datos e intente nuevamente.',
    de: 'Die Bank hat die Zahlung abgelehnt, bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut.',
    nl: 'De bank heeft de betaling geweigerd, controleer uw gegevens en probeer het opnieuw.',
  },
  paymentFailedUnsupportedProvider: {
    id: `${passengersInfoScope}.paymentFailedUnsupportedProvider`,
    defaultMessage:
      'Le fournisseur de paiement n’est pas pris en charge. Veuillez utiliser un autre moyen de paiement.',
    en: 'The payment provider is not supported. Please use a different payment method.',
    it: 'Il fornitore di pagamento non è supportato. Si prega di utilizzare un altro metodo di pagamento.',
    es: 'El proveedor de pago no es compatible. Por favor use un método de pago diferente.',
    de: 'Der Zahlungsanbieter wird nicht unterstützt. Bitte verwenden Sie eine andere Zahlungsmethode.',
    nl: 'De betalingsprovider wordt niet ondersteund. Gebruik alstublieft een andere betaalmethode.',
  },
  paymentFailedThreeDsFailed: {
    id: `${passengersInfoScope}.paymentFailedThreeDsFailed`,
    defaultMessage:
      'La vérification 3D Secure a échoué. Veuillez vérifier vos informations et réessayer.',
    en: 'The 3D Secure verification has failed. Please check your details and try again.',
    it: 'La verifica 3D Secure è fallita. Si prega di controllare i propri dati e riprovare.',
    es: 'La verificación 3D Secure ha fallado. Por favor verifique sus datos e intente nuevamente.',
    de: 'Die 3D-Secure-Verifikation ist fehlgeschlagen. Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut.',
    nl: 'De 3D Secure verificatie is mislukt. Controleer uw gegevens en probeer het opnieuw.',
  },
  paymentFailedMissingInfo: {
    id: `${passengersInfoScope}.paymentFailedMissingInfo`,
    defaultMessage:
      'Des informations sont manquantes. Veuillez compléter tous les champs requis et réessayer.',
    en: 'Information is missing. Please complete all required fields and try again.',
    it: 'Mancano alcune informazioni. Si prega di completare tutti i campi richiesti e riprovare.',
    es: 'Falta información. Por favor complete todos los campos requeridos e intente nuevamente.',
    de: 'Informationen fehlen. Bitte füllen Sie alle erforderlichen Felder aus und versuchen Sie es erneut.',
    nl: 'Informatie ontbreekt. Vul alstublieft alle verplichte velden in en probeer het opnieuw.',
  },
  paymentFailedCapacityExceeded: {
    id: `${passengersInfoScope}.paymentFailedCapacityExceeded`,
    defaultMessage:
      'Votre plafond de paiement a été dépassée. Veuillez réessayer plus tard.',
    en: 'Payment capacity has been exceeded. Please try again later.',
    it: 'La capacità di pagamento è stata superata. Si prega di riprovare più tardi.',
    es: 'Se ha excedido la capacidad de pago. Por favor, inténtelo de nuevo más tarde.',
    de: 'Zahlungskapazität wurde überschritten. Bitte versuchen Sie es später erneut.',
    nl: 'Betalingscapaciteit is overschreden. Probeer het alstublieft later opnieuw.',
  },
  paymentFailedInvalidCard: {
    id: `${passengersInfoScope}.paymentFailedInvalidCard`,
    defaultMessage:
      'La carte est invalide. Veuillez vérifier vos informations et réessayer.',
    en: 'The card is invalid. Please check your details and try again.',
    it: 'La carta non è valida. Si prega di controllare i propri dati e riprovare.',
    es: 'La tarjeta no es válida. Por favor verifique sus datos e intente nuevamente.',
    de: 'Die Karte ist ungültig. Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut.',
    nl: 'De kaart is ongeldig. Controleer uw gegevens en probeer het opnieuw.',
  },
  paymentFailedExpiredCard: {
    id: `${passengersInfoScope}.paymentFailedExpiredCard`,
    defaultMessage:
      'La carte a expiré. Veuillez utiliser une autre carte et réessayer.',
    en: 'The card has expired. Please use a different card and try again.',
    it: 'La carta è scaduta. Si prega di utilizzare un’altra carta e riprovare.',
    es: 'La tarjeta ha expirado. Por favor use una tarjeta diferente e intente nuevamente.',
    de: 'Die Karte ist abgelaufen. Bitte verwenden Sie eine andere Karte und versuchen Sie es erneut.',
    nl: 'De kaart is verlopen. Gebruik alstublieft een andere kaart en probeer het opnieuw.',
  },
  paymentFailedMaxAttemptsReached: {
    id: `${passengersInfoScope}.paymentFailedMaxAttemptsReached`,
    defaultMessage:
      'Le nombre maximal de tentatives de paiement a été atteint. Veuillez réessayer plus tard.',
    en: 'Maximum payment attempts have been reached. Please try again later.',
    it: 'Il numero massimo di tentativi di pagamento è stato raggiunto. Si prega di riprovare più tardi.',
    es: 'Se ha alcanzado el número máximo de intentos de pago. Por favor, inténtelo de nuevo más tarde.',
    de: 'Die maximale Anzahl der Zahlungsversuche wurde erreicht. Bitte versuchen Sie es später erneut.',
    nl: 'Maximaal aantal betalingspogingen is bereikt. Probeer het alstublieft later opnieuw.',
  },
  paymentFailedSessionExpired: {
    id: `${passengersInfoScope}.paymentFailedSessionExpired`,
    defaultMessage: 'Votre session de paiement a expiré. Veuillez réessayer.',
    en: 'Your payment session has expired. Please try again.',
    it: 'La sessione di pagamento è scaduta. Si prega di riprovare.',
    es: 'Su sesión de pago ha expirado. Por favor, inténtelo de nuevo.',
    de: 'Ihre Zahlungssitzung ist abgelaufen. Bitte versuchen Sie es erneut.',
    nl: 'Uw betalingssessie is verlopen. Probeer het opnieuw.',
  },
  successfullyUpdated: {
    id: `${passengersInfoScope}.successfullyUpdated`,
    defaultMessage: 'Les modifications ont bien été prises en compte',
    en: 'Changes have been successfully applied',
    it: 'Le modifiche sono state applicate correttamente',
    es: 'Los cambios se han aplicado correctamente',
    de: 'Die Änderungen wurden erfolgreich angewendet',
    nl: 'Wijzigingen zijn succesvol toegepast',
  },
  passengerLimitReached: {
    id: `${passengersInfoScope}.passengerLimitReached`,
    defaultMessage:
      'La limite de passagers ({count}) est atteinte. Pour dépasser ce nombre, contactez-nous ou faites plusieurs commandes. Sinon partez sans eux (on plaisante bien-sûr).',
    en: 'The passenger limit ({count}) has been reached. To exceed this number, contact us or place multiple orders. Otherwise, leave without them (just kidding of course).',
    it: 'Il limite dei passeggeri ({count}) è stato raggiunto. Per superare questo numero, contattaci o effettua più ordini. Altrimenti, parti senza di loro (scherziamo ovviamente).',
    es: 'Se ha alcanzado el límite de pasajeros ({count}). Para superar este número, contáctenos o realice múltiples pedidos. De lo contrario, váyase sin ellos (es una broma, por supuesto).',
    de: 'Die maximale Anzahl an Fahrgästen ({count}) wurde erreicht. Um diese Anzahl zu überschreiten, kontaktieren Sie uns oder tätigen Sie mehrere Bestellungen. Andernfalls fahren Sie ohne sie fort (natürlich nur ein Scherz).',
    nl: 'Het maximum aantal passagiers ({count}) is bereikt. Neem contact met ons op of plaats meerdere bestellingen om dit aantal te overschrijden. Anders, vertrek zonder hen (grapje natuurlijk).',
  },
  discardPassengerChangesWarning: {
    id: `${passengersInfoScope}.discardPassengerChangesWarning`,
    defaultMessage:
      'Si vous partez sans valider, les modifications ne seront pas appliquées aux passagers.',
    en: 'If you leave without saving, the changes will not be applied to the passengers.',
    it: 'Se lasci senza validare, le modifiche non saranno applicate ai passeggeri.',
    es: 'Si te vas sin validar, los cambios no se aplicarán a los pasajeros.',
    de: 'Wenn Sie ohne Bestätigung gehen, werden die Änderungen nicht auf die Passagiere angewendet.',
    nl: 'Als je vertrekt zonder te valideren, worden de wijzigingen niet toegepast op de passagiers.',
  },
  discardChangesWarning: {
    id: `${passengersInfoScope}.discardChangesWarning`,
    defaultMessage:
      'Si vous partez sans sauvegarder, les modifications ne seront pas appliquées',
    en: 'If you leave without saving, the changes will not be applied',
    it: 'Se esci senza salvare, le modifiche non verranno applicate',
    es: 'Si sales sin guardar, los cambios no se aplicarán',
    de: 'Wenn Sie ohne Speichern verlassen, werden die Änderungen nicht übernommen',
    nl: 'Als u vertrekt zonder op te slaan, worden de wijzigingen niet toegepast',
  },
  backToPassengerEdition: {
    id: `${passengersInfoScope}.backToPassengerEdition`,
    defaultMessage: 'Revenir aux passagers',
    en: 'Go back to passengers',
    it: 'Torna ai passeggeri',
    es: 'Volver a los pasajeros',
    de: 'Zurück zu den Passagieren',
    nl: 'Terug naar passagiers',
  },
  backToTicketEdition: {
    id: `${passengersInfoScope}.backToTicketEdition`,
    defaultMessage: 'Revenir à la modification des billets',
    en: 'Return to Ticket Modification',
    it: 'Torna alla Modifica dei Biglietti',
    es: 'Volver a la Modificación de Boletos',
    de: 'Zurück zur Ticketänderung',
    nl: 'Terug naar Ticketaanpassing',
  },
  leaveWithoutSaving: {
    id: `${passengersInfoScope}.leaveWithoutSaving`,
    defaultMessage: 'Partir sans valider',
    en: 'Leave without validation',
    it: 'Parti senza validare',
    es: 'Irse sin validar',
    de: 'Verlassen ohne Bestätigung',
    nl: 'Vertrekken zonder valideren',
  },
  notAvailableCart: {
    id: `${passengersInfoScope}.notAvailableCart`,
    defaultMessage:
      "L'un de vos billets n'est plus disponible auprès du transporteur.",
    en: 'One of your tickets is no longer available from the carrier.',
    it: 'Uno dei tuoi biglietti non è più disponibile presso il vettore.',
    es: 'Uno de tus boletos ya no está disponible en la compañía de transporte.',
    de: 'Eines Ihrer Tickets ist nicht mehr beim Transportunternehmen verfügbar.',
    nl: 'Eén van uw tickets is niet langer beschikbaar bij de vervoerder.',
  },
  notAvailableCartCTA: {
    id: `${passengersInfoScope}.notAvailableCartCTA`,
    defaultMessage: 'Relancer la recherche',
    en: 'Retry search',
    it: 'Potenziare la ricerca',
    es: 'Relanzar la búsqueda',
    de: 'Suche wiederholen',
    nl: 'Er is een fout opgetreden',
  },
  age: {
    id: `${passengersInfoScope}.age`,
    defaultMessage: '{age} ans',
    en: '{age} years old',
    it: '{age} anni',
    es: '{age} años',
    de: '{age} Jahre alt',
    nl: '{age} jaar',
  },
  [FORM_VALIDATION_REQUIRED_FIELD]: {
    id: `${passengersInfoScope}.${FORM_VALIDATION_REQUIRED_FIELD}`,
    defaultMessage: 'Ce champ est requis',
    en: 'This field is required',
    it: 'Questo campo è obbligatorio',
    es: 'Este campo es obligatorio',
    de: 'Dieses Feld ist erforderlich',
    nl: 'Dit veld is verplicht',
  },
  [FORM_VALIDATION_INVALID_DATE]: {
    id: `${passengersInfoScope}.${FORM_VALIDATION_INVALID_DATE}`,
    defaultMessage:
      'La date saisie est invalide. Veuillez vérifier votre saisie.',
    en: 'The entered date is invalid. Please check your input.',
    it: "La data inserita non è valida. Si prega di controllare l'inserimento.",
    es: 'La fecha ingresada no es válida. Por favor, verifique su entrada.',
    de: 'Das eingegebene Datum ist ungültig. Bitte überprüfen Sie Ihre Eingabe.',
    nl: 'De ingevoerde datum is ongeldig. Controleer uw invoer.',
  },
  [FORM_VALIDATION_ERROR]: {
    id: `${passengersInfoScope}.${FORM_VALIDATION_ERROR}`,
    defaultMessage: 'Veuillez entrer une valeur valide pour ce champ.',
    en: 'Please enter a valid value for this field.',
    it: 'Si prega di inserire un valore valido per questo campo.',
    es: 'Por favor, ingrese un valor válido para este campo.',
    de: 'Bitte geben Sie einen gültigen Wert für dieses Feld ein.',
    nl: 'Voer een geldige waarde in voor dit veld.',
  },
  [FORM_VALIDATION_INVALID_EMAIL]: {
    id: `${passengersInfoScope}.${FORM_VALIDATION_INVALID_EMAIL}`,
    defaultMessage: 'Veuillez saisir une adresse e-mail valide.',
    en: 'Please enter a valid email address.',
    it: 'Si prega di inserire un indirizzo email valido.',
    es: 'Por favor, ingrese una dirección de correo electrónico válida.',
    de: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
    nl: 'Voer een geldig e-mailadres in.',
  },

  redirectMessage: {
    id: `${passengersInfoScope}.redirectMessage`,
    defaultMessage:
      'Votre trajet "{originCity} - {destinationCity}" n’est pas compris dans cette commande. Une fois vos autres trajets réservés, vous serez redirigé vers {company} pour le réserver. Facile, non ? ✌',
    en: 'Your trip "{originCity} - {destinationCity}" is not included in this order. Once your other trips are booked, you will be redirected to {company} to book it. Easy, peasy. ✌',
    it: 'Il tuo viaggio "{originCity} - {destinationCity}" non è incluso in questo ordine. Una volta prenotati gli altri viaggi, sarai reindirizzato su {company} per prenotarlo. Facile, vero? ✌',
    es: 'Tu viaje "{originCity} - {destinationCity}" no está incluido en esta orden. Una vez que reserves tus otros viajes, serás redirigido a {company} para reservarlo. Fácil, ¿verdad? ✌',
    de: 'Ihre Reise "{originCity} - {destinationCity}" ist nicht in dieser Bestellung enthalten. Sobald Ihre anderen Reisen gebucht sind, werden Sie zur Buchung auf {company} weitergeleitet. Ganz einfach. ✌',
    nl: 'Uw reis "{originCity} - {destinationCity}" is niet in deze bestelling inbegrepen. Zodra uw andere reizen zijn geboekt, wordt u doorgestuurd naar {company} om het te boeken. Gemakkelijk, toch? ✌',
  },
  redirectMessageTitle: {
    id: `${passengersInfoScope}.redirectMessageTitle`,
    defaultMessage: 'Votre voyage comporte un trajet {companies}',
    en: 'Your journey includes an {companies} trip',
    it: 'Il tuo viaggio include un viaggio con {companies}',
    es: 'Tu viaje incluye un viaje en {companies}',
    de: 'Ihre Reise beinhaltet eine Fahrt mit {companies}',
    nl: 'Uw reis omvat een {companies}-reis',
  },
  newSearch: {
    id: `${passengersInfoScope}.newSearch`,
    defaultMessage: 'Nouvelle recherche',
    en: 'New Search',
    it: 'Nuova ricerca',
    es: 'Nueva búsqueda',
    de: 'Neue Suche',
    nl: 'Nieuwe zoekopdracht',
  },
  refresh: {
    id: `${passengersInfoScope}.refresh`,
    defaultMessage: 'Actualiser la page',
    en: 'Refresh',
    it: 'Aggiorna',
    es: 'Actualizar página',
    de: 'Seite aktualisieren',
    nl: 'Pagina vernieuwen',
  },
  modalError: {
    id: `${passengersInfoScope}.modalError`,
    defaultMessage:
      "Une erreur s'est produite lors de la récupération de votre commande.",
    en: 'An error occurred while retrieving your order.',
    it: 'Si è verificato un errore durante il recupero del tuo ordine.',
    es: 'Se ha producido un error al recuperar su pedido.',
    de: 'Ein Fehler ist bei der Abfrage Ihrer Bestellung aufgetreten.',
    nl: 'Er is een fout opgetreden bij het ophalen van uw bestelling.',
  },
  modalPaymentError: {
    id: `${passengersInfoScope}.modalPaymentError`,
    defaultMessage:
      "Une erreur s'est produite lors de votre tentative de paiement.",
    en: 'An error occurred during your payment attempt.',
    it: 'Si è verificato un errore durante il tentativo di pagamento.',
    es: 'Se produjo un error durante su intento de pago.',
    de: 'Bei Ihrer Zahlung ist ein Fehler aufgetreten.',
    nl: 'Er is een fout opgetreden tijdens uw betaalpoging.',
  },
  modalPriceChange: {
    id: `${passengersInfoScope}.modalPriceChange`,
    defaultMessage:
      'Nous avons remarqué un changement de prix pour votre commande. Le nouveau montant total est maintenant de {amount}',
    en: 'We noticed a price change for your order. The new total amount is now {amount}',
    it: 'Abbiamo notato una variazione di prezzo per il tuo ordine. La nuova quantità totale è ora di {amount}',
    es: 'Hemos notado un cambio de precio en tu pedido. El nuevo monto total es ahora de {amount}',
    de: 'Wir haben eine Preisänderung für Ihre Bestellung festgestellt. Der neue Gesamtbetrag beträgt jetzt {amount}',
    nl: 'We hebben een prijswijziging opgemerkt voor uw bestelling. Het nieuwe totale bedrag is nu {amount}',
  },
  continue: {
    id: `${passengersInfoScope}.continue`,
    defaultMessage: 'Continuer',
    en: 'Continue',
    it: 'Continuare',
    es: 'Continuar',
    de: 'Weiter',
    nl: 'Doorgaan',
  },
  cancel: {
    id: `${passengersInfoScope}.cancel`,
    defaultMessage: 'Annuler',
    en: 'Cancel',
    it: 'Annulla',
    es: 'Cancelar',
    de: 'Abbrechen',
    nl: 'Annuleren',
  },
  retry: {
    id: `${passengersInfoScope}.retry`,
    defaultMessage: 'Réessayer',
    en: 'Retry',
    it: 'Riprova',
    es: 'Reintentar',
    de: 'Erneut versuchen',
    nl: 'Opnieuw proberen',
  },
  tryAgain: {
    id: `${passengersInfoScope}.tryAgain`,
    defaultMessage: 'Veuillez réessayer.',
    en: 'Please try again.',
    it: 'iprova.',
    es: 'Por favor, inténtelo de nuevo.',
    de: 'Bitte versuchen Sie es erneut.',
    nl: 'Probeer het opnieuw.',
  },
  includedProviderFeeCentsTooltip: {
    id: `${passengersInfoScope}.includedProviderFeeCentsTooltip`,
    defaultMessage: 'Dont {includedProviderFeeCents} de frais de service',
    en: 'Including {includedProviderFeeCents} service fees',
    it: 'Di cui {includedProviderFeeCents} sono spese per il servizio',
    es: 'De los cuales {includedProviderFeeCents} son tasas de servicio',
    de: 'Davon {includedProviderFeeCents} Servicegebühren',
    nl: 'Waarvan {includedProviderFeeCents} servicekosten zijn',
  },
};

export default defineMessages(passengersInfoMessages);
