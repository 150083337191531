import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { useApp } from '../../contexts/App';
import { CloseIcon } from '../../images/icons/close';
import AppMockup200w from '../../images/illustrations/app-homepage-mockup.png';
import { colors, screens } from '../../styles/constants';
import { Icon12 } from '../Icon';
import messages from './messages';
import {
  appLink,
  canOpenBanner,
  getMobilePlatform,
  setAppBannerCloseTTL,
  UNKNOWN_PLATFORM,
} from './utils';

const Container = styled.div`
  ${tw`w-full flex items-center bg-white shadow-strong`}
`;

const MainWrapper = styled.div`
  ${tw`flex justify-center w-full`}
`;

const Content = styled.div`
  ${tw` py-small flex flex-col`}
`;

const Title = styled.p`
  ${tw`font-kyrial text-title-medium-m leading-title-medium-m`}
`;

const StoreLink = styled.a`
  ${tw`text-p-medium-m leading-p-medium-m text-primary-400 underline`}
`;

const CloseButton = styled.button`
  ${tw`p-0 pr-medium`}
`;

const ImageContainer = styled.div`
  ${tw`overflow-hidden`}
  max-width:70px;
  max-height: 70px;

  @media (min-width: ${screens.xs}) {
    max-width: 116px;
  }
`;

const Image = styled.img`
  ${tw`block mx-auto h-auto`}
  width: 80%;
`;

const AppPromotionBanner = () => {
  const { isInFunnel, isLoading, partner } = useApp();
  const platform = getMobilePlatform();
  const [isDisplayed, setIsDisplayed] = useState(false);

  useEffect(() => {
    const isWidgetBooking = partner?.id.startsWith('WB-');

    const isDisplayed =
      !isLoading &&
      !isWidgetBooking &&
      canOpenBanner() &&
      !isInFunnel &&
      platform !== UNKNOWN_PLATFORM;

    setIsDisplayed(isDisplayed);
  }, [isInFunnel, platform, isLoading, partner]);

  const closeBanner = () => {
    setIsDisplayed(false);
    setAppBannerCloseTTL();
  };

  if (!isDisplayed) {
    return null;
  }

  return (
    <Container>
      <MainWrapper>
        <ImageContainer>
          <Image alt="Tictactrip Application mockup" src={AppMockup200w} />
        </ImageContainer>
        <Content>
          <Title>
            <FormattedMessage {...messages.title} />
          </Title>
          <StoreLink
            href={appLink[platform]}
            rel="noopener noreferrer"
            target="_blank"
          >
            <FormattedMessage {...messages.getInStore} />
          </StoreLink>
        </Content>
      </MainWrapper>

      <CloseButton onClick={closeBanner}>
        <CloseIcon color={colors['primary-400']} customStyle={Icon12} />
      </CloseButton>
    </Container>
  );
};

export default AppPromotionBanner;
